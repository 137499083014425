import { reactive } from "vue";
import { globalValues } from "./globalValuesComp";
import { SurgaProperty } from "./models/surgaapi/surgaPropertyModel";
import { stateStorage } from "./stateStorageComp";

const watchlistItems = reactive({
  list: [] as string[],
  // increments if clearAll function has been run.
  properties: [] as SurgaProperty[],
  clearState: 0 as number,
  apiState: "idle" as string
});
const watchlistToken: string = "watchlist-items";
const watchlistRoute: string = "/api/surgaweb/getwatchlist";

const agencyId = document
  .getElementsByTagName("version")[0]
  .getAttribute("data-agency-id");

// Manages the watchlist functionality.
function watchlist() {
  // initiates watchlist
  function initiate() {
    const watchlistObject = stateStorage(watchlistToken).get()
      .value as unknown as string[];
    if (watchlistObject !== null && Object.keys(watchlistObject).length !== 0) {
      watchlistItems.list = watchlistObject;
    }
  }
  // adds item to watchlist. Includes listing Type.
  function add(listingId: string) {
    const index = watchlistItems.list.indexOf(listingId);
    if (index === -1) {
      watchlistItems.list.push(listingId);
    }
    setStorage();
  }
  // removes item from watchlist. Includes listing Type.
  function remove(listingId: string) {
    const index = watchlistItems.list.indexOf(listingId);
    if (index > -1) {
      watchlistItems.list.splice(index, 1);
    }
    setStorage();
  }
  // removes all instances of a property in watchlist by ID. Does not include listing Type.
  function removeAllById(listingId: string) {
    let complete: boolean;
    do {
      complete = true;
      const index = findIndexById(listingId);
      if (index !== -1) {
        watchlistItems.list.splice(index, 1);
        complete = false;
      }
    } while (!complete);
    setStorage();
  }
  // finds the first index of record in watchlist by SurgaId
  function findIndexById(surgaId: string): number {
    const trimmedId = surgaId.trim().toLowerCase();
    for (const index in watchlistItems.list) {
      const propertyStringList: string[] =
        watchlistItems.list[index].split("|");
      if (trimmedId === propertyStringList[0].trim().toLowerCase()) {
        return index as unknown as number;
      }
    }
    return -1;
  }
  // retrieves properties from surga database.
  async function getProperties(): Promise<void> {
    if (watchlistItems.apiState === "idle") {
      watchlistItems.apiState = "processing";
      let baseUrl = globalValues().get("searchUrl", "");
      if (baseUrl.endsWith("/")) {
        baseUrl = baseUrl.substr(0, baseUrl.length - 1);
      }
      const apiRoute =
        baseUrl +
        watchlistRoute +
        "?agencyId=" +
        agencyId +
        "&listingIds=" +
        getIds();
      const apiReply: Record<string, any>[] = (await callSurgaApi(
        apiRoute
      )) as unknown as Record<string, any>[];
      watchlistItems.properties = recreateWatchlist(apiReply);
      watchlistItems.apiState = "idle";
    }
  }

  //generates watchlist Address
  function generateWatchlistAddress(property: SurgaProperty): string {
    let addressString: string = "";
    if (!property.DisplayAddress) {
      if (property.SuburbName) {
        addressString = addressString + property.SuburbName;
      }
      if (property.PostCode) {
        addressString = addressString + " " + property.PostCode;
      }
    } else {
      if (property.SiteName) {
        addressString = addressString + property.SiteName + ", ";
      }
      if (property.StreetNumber) {
        addressString = addressString + property.StreetNumber;
      }
      if (property.StreetName) {
        addressString = addressString + " " + property.StreetName + ", ";
      }
      if (property.SuburbName) {
        addressString = addressString + property.SuburbName;
      }
      if (property.PostCode) {
        addressString = addressString + " " + property.PostCode;
      }
    }
    addressString = addressString.trim();
    while(addressString[addressString.length-1] === ",") {
      addressString = addressString.slice(0, -1);
      addressString = addressString.trim();
    }
    return addressString;
  }

  // get ids list from watchlist item list.
  function getIds(): string {
    const newIdList: string[] = [];
    let resultString: string = "";
    for (const propertyIdString of watchlistItems.list) {
      const list: string[] = propertyIdString.split("|");
      if (newIdList.indexOf(list[0]) === -1) {
        newIdList.push(list[0]);
      }
    }
    for (const propertyId of newIdList) {
      if (resultString.length === 0) {
        resultString += propertyId;
      } else {
        resultString += ";" + propertyId;
      }
    }
    return resultString;
  }

  // Returns true if property with ID is in watchlist, false if property is not.
  function isInWatchlist(propertyId: string): boolean {
    const trimmedId = propertyId.trim().toLowerCase();
    for (const propertyIdString of watchlistItems.list) {
      const list: string[] = propertyIdString.split("|");
      if (trimmedId === list[0].trim().toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  // places searched surga properties back into watchlist properties.
  function recreateWatchlist(apiReply: Record<string, any>[]): SurgaProperty[] {
    const resultList: SurgaProperty[] = [];
    for (const propertyIdString of watchlistItems.list) {
      const list: string[] = propertyIdString.split("|");
      const result: SurgaProperty | null = returnPropertyByWebId(
        list[0],
        apiReply
      );
      if (result !== null) {
        result._websiteListingType = list[1];
        resultList.push(result);
      }
    }
    return resultList;
  }

  // returns correct property based on web listingId.
  function returnPropertyByWebId(
    webId: string,
    apiReply: Record<string, any>[]
  ): SurgaProperty | null {
    for (const property of apiReply) {
      if (webId.toLowerCase() === property.SurgaWebListingId.toLowerCase()) {
        const returnProperty = new SurgaProperty();
        returnProperty.setupListing(property);
        return returnProperty;
      }
    }
    return null;
  }

  // clear all
  function clearAll() {
    watchlistItems.list = [];
    watchlistItems.properties = [];
    watchlistItems.clearState++;
    setStorage();
  }

  function getWatchlistAddresses() {
    // set address value
    const watchlistAddresses: string[] = [];
    for (let property of watchlistItems.properties) {
      if (checkId(property.SurgaWebListingId)) {
        let address: string = generateWatchlistAddress(
          property as SurgaProperty
        );
        if (address) {
          watchlistAddresses.push(address);
        }
      }
    }
    return watchlistAddresses;
  }

  function checkId(id: string): boolean {
    for (let webId of watchlistItems.list) {
      if (webId.includes(id)) {
        return true;
      }
    }
    return false;
  }

  // calls surga API.
  async function callSurgaApi(route: string): Promise<object> {
    const json = await (await fetch(route)).json();
    const data = json.Data;
    return data;
  }
  // returns reactive items
  function state() {
    return watchlistItems;
  }
  function setStorage() {
    stateStorage(watchlistToken).set(watchlistItems.list);
  }
  async function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  return {
    add,
    remove,
    removeAllById,
    state,
    initiate,
    getProperties,
    isInWatchlist,
    clearAll,
    getWatchlistAddresses
  };
}
export { watchlist };
